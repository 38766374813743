<template>
	<b-card title="Page List">
		<b-row class="align-items-center mb-1">
			<b-col cols="12" md="6">
				<router-link
					to="/create-page"
					variant="primary"
					class="btn waves-effect waves-float waves-light btn-primary"
					v-if="checkPermission('add page')"
				>
					Create Page
				</router-link>
			</b-col>
		</b-row>
		<b-table 
			striped 
			hover 
			:items="pages"
			:fields="fields"
			:busy="isLoading"
			show-empty
			responsive
		>
			<template #table-busy>
				<div class="text-center text-secondary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>

			<template v-slot:cell(no)="{ index }">
				{{ index + 1 }}
			</template>	

			<template v-slot:cell(actions)="{ item }">
				<div class="d-flex" style="gap:12px;">
					<router-link
						:to="'/edit-page/'+ item.uuid"
					>
						<b-badge 
							variant="warning"
							class="badge-glow"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-edit-icon`"
								icon="EditIcon"
								class="cursor-pointer"
								size="16"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.bottom="'Edit Data'"
								v-if="checkPermission('update page')"
							/>
						</b-badge>
					</router-link>

					<b-badge 
						variant="danger"
						class="badge-glow"
					>
						<feather-icon
							:id="`invoice-row-${item.uuid}-delete-icon`"
							icon="TrashIcon"
							size="16"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Delete Data'"
							@click="deleteItem(item.uuid)"
							v-if="checkPermission('delete page')"
						/>
					</b-badge>
					
				</div>
			</template>
		</b-table>
	</b-card>
</template>

<script>
import { 
	BCard,
	BCardText,
	BTable,
	BPagination,
	BButton,
	BSpinner,
	BBadge,
	BRow,
	BCol,
	VBModal,
	VBTooltip,
	BFormSelect
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'
export default {
	components: {
		BCard,
		BCardText,
		BTable,
		BPagination,
		BButton,
		BSpinner,
		BBadge,
		BRow,
		BCol,
		BFormSelect
	},
	directives: {
	'b-tooltip': VBTooltip,
    'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			pages:[],
			fields: ['no','title', 'slug', 'actions'],
			isLoading: false,
		}
	},
	computed: {
		rows() {
			return this.pages.length
		}
	},
	setup() {
		return {
			checkPermission,
			successNotification, 
			errorNotification
		}
	},
	methods: {
		getData() { //page = 1
			this.isLoading = true
			this.$http.get('admin/pages'
			).then(response => {
				this.pages = response.data.data
				this.isLoading = false
			})
		},
		deleteItem(item) {
			this.$swal({
				title: 'Apakah Anda yakin?',
				icon: 'info',
				text: 'Anda akan menghapus Page ini. Data yang sudah terhapus tidak dapat dipulihkan.',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				confirmButtonText: 'Ya, hapus data ini',
				cancelButtonText: 'Batal',
				buttonsStyling: false
			}).then(async result =>{
				if(result.value) {
					await this.$http.delete('admin/pages/' + item)
						this.getData()
						successNotification(this, 'Success', 'Page Sukses Dihapus!')
				}
			}).catch(error => {
				errorNotification(this, 'Oops!', 'Ada Kendala Teknis')
			})
		}
	},
	created() {
		this.getData()
	}
}
</script>